<template>
  <div class="resize">
    <label class="resize-row standart-title">{{ $t('attach') }}</label>
    <div class="l-col">
      <base-select
        v-model="float"
        :placeholder="hasPorts"
        :hide-selected="true"
        select-label=""
        :searchable="false"
        :options="options"
        size="medium"
        :custom-label="namePort"
        :allow-empty="false"
        :preselect-first="true"
        class="resize-row-item"
        @input="onChange"
      >
      </base-select>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import networks from '@/layouts/Stack/mixins/networks';
export default {
  name: 'AttachPortToFloatIp',
  components: { BaseSelect },
  mixins: [setFocus, networks],
  data() {
    return {
      float: [],
    };
  },
  computed: {
    hasPorts() {
      if (this.options && this.options.length === 0) {
        return this.$t('noPorts');
      } else {
        return this.$t('default', 'en');
      }
    },
    // portsWithoutDevice() {
    //   return (
    //     this.$store.state.moduleStack.ports
    //       .filter(
    //         x => !x.device_id && !x.device_owner && this.networksPrivate.includes(x.network_id)
    //       )
    //       // .filter(x => !x.device_id && !x.device_owner)
    //       .map(x => `${x.fixed_ips[0].ip_address}`)
    //       .filter(x => !this.floatips.includes(x))
    //   );
    // },
    floatips() {
      return this.$store.state.moduleStack.floatingips
        .filter(x => x.fixed_ip_address !== null)
        .map(x => x.fixed_ip_address);
    },
    portsWithDevice() {
      return this.$store.state.moduleStack.ports
        .filter(
          x => x.device_owner === 'compute:nova' && this.networksPrivate.includes(x.network_id)
        )
        .map(x => `${x.fixed_ips[0].ip_address}`)
        .filter(x => !this.floatips.includes(x));
    },
    options() {
      // return this.portsWithDevice.concat(this.portsWithoutDevice);
      return this.portsWithDevice; //.concat(this.portsWithoutDevice);
    },
  },
  methods: {
    getPortId(port) {
      return this.$store.state.moduleStack.ports.find(x =>
        x.fixed_ips[0] ? x.fixed_ips[0].ip_address === port : null
      ).id;
    },
    getPortDeviceId(port) {
      // console.log(this.$store.state.moduleStack.ports.map(x => x.fixed_ips[0]));
      return this.$store.state.moduleStack.ports.find(x =>
        x.fixed_ips[0] ? x.fixed_ips[0].ip_address === port : ''
      ).device_id;
    },
    namePort(name) {
      const name_ = this.$store.state.moduleStack.servers.find(
        x => x.id === this.getPortDeviceId(name)
      )
        ? this.$store.state.moduleStack.servers.find(x => x.id === this.getPortDeviceId(name)).name
        : null;
      return name_ ? `${name_}: ${name}` : `${name}`;
    },
    onChange(event) {
      this.$emit('float', this.getPortId(event));
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "attach": "Подключить плавающий IP к порту:",
    "noPorts": "Нет доступных портов",
    "sure": {
      "confirm": "Подключить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  },
  "en": {
    "default": "Select option"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
