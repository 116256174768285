import AttachPortToFloatIp from '@/layouts/Stack/components/AttachPortToFloatIp.vue';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import storeMixin from '@/layouts/Stack/mixins/fetch';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorOpenStackModal, storeMixin, modals],
  computed: {},
  methods: {
    attachPortToFloatIp: function (port, float) {
      // console.log(port, float);
      const that = this;
      return new Promise(() => {
        const selfName = 'AttachPortToFloatIp';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: AttachPortToFloatIp,
          closeOnBackdrop: false,
          props: {
            port: port,
            float: float,
          },
          text: this.$t('sure.text'),
          on: {
            port: data => {
              port = data;
            },
            float: data => {
              float = data;
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  this.attachFloatingIpToPort(port, float)
                    .then(data => {
                      // console.log(data);
                      if (data.status === 200) {
                        this.$modals.close({ name: selfName });
                        this.newFetch();
                        this.showResModal('Плавающий IP подключён.');
                      } else this.$modals.close({ name: selfName });
                    })
                    .catch(() => this.$modals.close({ name: selfName }));
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    attachFloatingIpToPort(id, port, ip) {
      // console.log(port, id, ip);
      const params = {
        port: port,
        id: id,
        ip: ip,
      };
      return this.$store.dispatch('moduleStack/attachFloatingIpToPort', params).catch(e => {
        this.showError(e);
      });
    },
    /*makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },*/
  },
};
